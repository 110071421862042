import React, { Fragment } from "react";
import "../../CSS/ErrorBoundary.css";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Fragment>
          <div className='error-container'>
            <div className='error-content'>
              <i className='fas fa-exclamation-triangle error'></i>
              <div className='error-message'>
                Hmm, something went wrong...
              </div>
              <div
                className='reload-button'
                onClick={() => window.location.reload()}
              >
                Reload Page
              </div>
            </div>
          </div>
        </Fragment>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
