import { combineReducers } from 'redux';
import alert from './alert';
import auth from './auth';
import athProfile from './athProfile';
import coachProfile from './coachProfile';
import orgProfile from './orgProfile';
import athData from './athData';
import timeline from './timeline';
import selectedGroup from './selectedGroup';

export default combineReducers({
  alert,
  auth,
  athProfile,
  coachProfile,
  orgProfile,
  athData,
  timeline,
  selectedGroup,
});
