export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const USER_UPDATED = 'USER_UPDATED';
export const UPDATE_FAIL = 'UPDATE_FAIL';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';

export const GET_ATH_PROFILE = 'GET_ATH_PROFILE';
export const GET_ATHLETE_PROFILE = 'GET_ATHLETE_PROFILE';
export const ORG_GET_ATH_PROFILE = 'ORG_GET_ATH_PROFILE';
export const CLEAR_ATHLETE_PROFILE = 'CLEAR_ATHLETE_PROFILE';
export const ORG_CLEAR_ATHLETE_PROFILE = 'ORG_CLEAR_ATHLETE_PROFILE';
export const GET_ATH_PROFILES = 'GET_ATH_PROFILES';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const CLEAR_PROFILE = 'CLEAR_PROFILE';
export const PROFILE_ERROR = 'PROFILE_ERROR';
export const ACCOUNT_DELETED = 'ACCOUNT_DELETED';
export const WATCHLIST_ADD = 'WATCHLIST_ADD';
export const WATCHLIST_REMOVE = 'WATCHLIST_REMOVE';
export const COACH_ADD_FOLLOW = 'COACH_ADD_FOLLOW';
export const COACH_REMOVE_FOLLOW = 'COACH_REMOVE_FOLLOW';
export const ATH_ADD_FOLLOW = 'ATH_ADD_FOLLOW';
export const ATH_REMOVE_FOLLOW = 'ATH_REMOVE_FOLLOW';
export const ORG_ADD_FOLLOW = 'ORG_ADD_FOLLOW';
export const ORG_REMOVE_FOLLOW = 'ORG_REMOVE_FOLLOW';
export const REQUEST_CONTACT_DETAILS = 'REQUEST_CONTACT_DETAILS';
export const ORG_REQUEST_CONTACT_DETAILS = 'ORG_REQUEST_CONTACT_DETAILS';
export const CONTACT_ENABLED = 'CONTACT_ENABLED';

export const ATHLETE_NOTIFICATIONS = 'ATHLETE_NOTIFICATIONS';
export const ATHLETE_NOTIFICATION_READ = 'ATHLETE_NOTIFICATION_READ';
export const COACH_NOTIFICATIONS = 'COACH_NOTIFICATIONS';
export const COACH_NOTIFICATION_READ = 'COACH_NOTIFICATION_READ';
export const ORG_NOTIFICATIONS = 'ORG_NOTIFICATIONS';
export const ORG_NOTIFICATION_READ = 'ORG_NOTIFICATION_READ';

export const GET_COACH_PROFILE = 'GET_COACH_PROFILE';
export const CLEAR_COACH_PROFILE = 'CLEAR_COACH_PROFILE';
export const COACH_PROFILE_ERROR = 'COACH_PROFILE_ERROR';
export const UPDATE_PROFILE_COACH = 'UPDATE_PROFILE_COACH';
export const LOADING_COACH = 'LOADING_COACH';

export const GET_ORGANISATION = 'GET_ORGANISATION';
export const CLEAR_ORGANISATION = 'CLEAR_ORGANISATION';
export const ORGANISATION_PROFILE_ERROR = 'ORGANISATION_PROFILE_ERROR';
export const UPDATE_ORGANISATION = 'UPDATE_ORGANISATION';
export const LOADING_ORGANISATION = 'LOADING_ORGANISATION';
export const ATH_GET_ORG_PROFILE = 'ATH_GET_ORG_PROFILE';
export const COACH_GET_ORG_PROFILE = 'COACH_GET_ORG_PROFILE';
export const ORG_GET_ORG_PROFILE = 'ORG_GET_ORG_PROFILE';

export const GET_OTHER_COACH_PROFILE = 'GET_OTHER_COACH_PROFILE';
export const GET_SELECTED_COACH_PROFILE = 'GET_SELECTED_COACH_PROFILE';
export const GET_COACH_PROFILES = 'GET_COACH_PROFILES';
export const ORG_GET_COACH_PROFILE = 'ORG_GET_COACH_PROFILE';

export const GET_ATH_DATA = 'GET_ATH_DATA';
export const DATA_ERROR = 'DATA_ERROR';
export const CLEAR_DATA = 'CLEAR_DATA';
export const RESET_DATA = 'RESET_DATA';

export const FETCH_TIMELINE = 'FETCH_TIMELINE';
export const TIMELINE_ERROR = 'TIMELINE_ERROR';
export const CLEAR_TIMELINE = 'CLEAR_TIMELINE';

export const GET_GROUP = 'GET_GROUP';
export const GROUP_ERROR = 'GROUP_ERROR';
export const UPDATE_GROUP = 'UPDATE_GROUP';
export const UPDATE_GROUP_MEMBERS = 'UPDATE_GROUP_MEMBERS';
