import React, { Fragment } from "react";
import spinner from '../../images/Spin-1.3s-194px.gif'

const MainSpinner = () => (
  <Fragment>
      <div className="loading-container main-spinner-container"></div>
      <div className="loading loading-main">
    <img
    className="loading-spinner"
      src={spinner} alt=''
    />
    </div>
  </Fragment>
);

export default MainSpinner;