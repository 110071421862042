import {
  GET_ORGANISATION,
  CLEAR_ORGANISATION,
  ORGANISATION_PROFILE_ERROR,
  ORG_GET_ORG_PROFILE,
  ORG_GET_COACH_PROFILE,
  ORG_GET_ATH_PROFILE,
  ORG_CLEAR_ATHLETE_PROFILE,
  ORG_ADD_FOLLOW,
  ORG_REMOVE_FOLLOW,
  UPDATE_ORGANISATION,
  LOADING_ORGANISATION,
  ORG_NOTIFICATIONS,
  ORG_NOTIFICATION_READ,
  ORG_REQUEST_CONTACT_DETAILS,
} from '../actions/types';

const initialState = {
  profile: null,
  profiles: [],
  selectedProfile: null,
  query: [],
  loading: true,
  error: {},
};

export default function orgReducer(state = initialState, action) {
  const { type, payload, orgProf } = action;
  switch (type) {
    case GET_ORGANISATION:
    case UPDATE_ORGANISATION:
      return {
        ...state,
        profile: payload,
        profiles: [],
        selectedProfile: null,
        query: [],
        loading: false,
      };
    case LOADING_ORGANISATION:
      return {
        ...state,
        loading: true,
      };
    case ORG_GET_ORG_PROFILE:
    case ORG_GET_COACH_PROFILE:
    case ORG_GET_ATH_PROFILE:
      return {
        ...state,
        profile: orgProf,
        selectedProfile: payload,
        loading: false,
      };
    case ORG_CLEAR_ATHLETE_PROFILE:
      return {
        ...state,
        selectedProfile: null,
        loading: true,
      };
    case ORG_NOTIFICATIONS:
    case ORG_NOTIFICATION_READ:
    case ORG_ADD_FOLLOW:
    case ORG_REMOVE_FOLLOW:
    case ORG_REQUEST_CONTACT_DETAILS:
      return {
        ...state,
        profile: payload,
        loading: false,
      };
    case ORGANISATION_PROFILE_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case CLEAR_ORGANISATION:
      return {
        ...state,
        profile: null,
        profiles: [],
        selectedProfile: null,
        query: [],
        loading: true,
      };
    default:
      return state;
  }
}
