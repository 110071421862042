import React, { Fragment } from "react";

const NavbarFallback = () => {
  return (
    <Fragment>
      <div
        className='nav-fallback'
        style={{ display: "block", color: "#242424" }}
      >
        ...
      </div>
    </Fragment>
  );
};

export default NavbarFallback;
