import React, { Fragment, useState, lazy, Suspense } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import '../../CSS/Navbar.css';
import NavbarFallback from '../fallback/NavbarFallback';
const AuthLinks = lazy(() => import('./navbar-links/AuthLinks'));
const GuestLinks = lazy(() => import('./navbar-links/GuestLinks'));

const Navbar = ({ auth: { isAuthenticated, loading, user } }) => {
  const [dropdownSelected, setDropdownSelected] = useState(false);
  return (
    <nav className='navbar bg-dark'>
      <div className='logo'>
        {user ? (
          <h1>
            ONS<span className='id'>ID</span>E
          </h1>
        ) : (
          <Link to='/'>
            <h1>
              ONS<span className='id'>ID</span>E
            </h1>
          </Link>
        )}
      </div>
      {!loading && (
        <Suspense fallback={<NavbarFallback />}>
          <Fragment>
            {isAuthenticated ? (
              <AuthLinks
                dropdownSelected={dropdownSelected}
                setDropdownSelected={setDropdownSelected}
              />
            ) : (
              <GuestLinks
                dropdownSelected={dropdownSelected}
                setDropdownSelected={setDropdownSelected}
              />
            )}
          </Fragment>
        </Suspense>
      )}
    </nav>
  );
};

Navbar.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(withRouter(Navbar));
