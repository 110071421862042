import {
  GET_ATH_DATA,
  DATA_ERROR,
  CLEAR_DATA,
  RESET_DATA,
} from "../actions/types";

const initialState = {
  data: null,
  loadingData: true,
  error: {},
};

export default function dataReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ATH_DATA:
      return {
        data: payload,
        loadingData: false,
        error: {},
      };
    case DATA_ERROR:
      return {
        ...state,
        error: payload,
        loadingData: false,
      };
    case CLEAR_DATA:
      return {
        data: null,
        loadingData: false,
        error: {},
      };
    case RESET_DATA:
      return {
        data: null,
        loadingData: true,
        error: {},
      };
    default:
      return state;
  }
}
