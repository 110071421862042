import React from "react";

const Fallback = () => {
  return (
    <React.Fragment>
      <div
        className='fallback-container'
        style={{
          height: "100%",
          width: "100%",
          backgroundColor: "transparent",
        }}
      ></div>
    </React.Fragment>
  );
};

export default Fallback;
