import {
  GET_GROUP,
  GROUP_ERROR,
  UPDATE_GROUP,
  UPDATE_GROUP_MEMBERS,
} from '../actions/types';

const initialState = {
  group: null,
  members: [],
  activity: [],
  loading: true,
  error: {},
};

export default function groupReducer(state = initialState, action) {
  const { type, payload, members, activity } = action;
  switch (type) {
    case GET_GROUP:
      return {
        ...state,
        group: payload,
        members: members,
        activity: activity,
        loading: false,
        error: {},
      };
    case UPDATE_GROUP_MEMBERS:
      return {
        ...state,
        group: payload,
        members: members,
        loading: false,
        error: {},
      };
    case UPDATE_GROUP:
      return {
        ...state,
        group: payload,
        activity: activity,
        loading: false,
        error: {},
      };
    case GROUP_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
}
