import React, { lazy, Suspense, useEffect, useState, Fragment } from 'react';
import '../../CSS/Landing.css';
import { Redirect, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Fallback from '../fallback/Fallback';
import landingMain from '../images/landing_images/athlete_profile_mobile.png';
import MainSpinner from './Spinners/MainSpinner';
const AthSwiper = lazy(() => import('./swipers/AthSwiper'));
const CoachSwiper = lazy(() => import('./swipers/CoachSwiper'));
const OrgSwiper = lazy(() => import('./swipers/OrgSwiper'));

const Landing = ({ isAuthenticated, user, loading }) => {
  const [loadComplete, setLoadComplete] = useState(false);

  useEffect(() => {
    if (!loading) {
      setLoadComplete(true);
    }
  }, [loading]);

  if (isAuthenticated && user.userType === 1) {
    return <Redirect to='/AthleteProfile' />;
  } else if (isAuthenticated && user.userType === 2) {
    return <Redirect to='/CoachProfile' />;
  }

  return (
    <Fragment>
      {!loadComplete ? (
        <MainSpinner />
      ) : (
        <Fragment>
          <section className='landing-container'>
            <div className='landing-1'>
              <div className='main-header'>
                <h1>
                  Welcome to ONS<span className='id'>ID</span>E
                </h1>
              </div>
              <div className='landing-1-inner'>
                <div className='img landing-main'>
                  <img fetchpriority='high' src={landingMain} alt='' />
                </div>
                <div className='text-1'>
                  <p>
                    Where athletes of all shapes, sizes and abilities can
                    display their talent and be matched with the opportunity
                    that best fits them
                  </p>
                </div>
                <div className='text-2'>
                  <p>
                    For scouts and coaches, here you will find the talent that
                    you need on your roster
                  </p>
                </div>
              </div>
            </div>
            <div className='landing-2-header'>
              <p>
                ONS<span className='id'>ID</span>E for Athletes
              </p>
            </div>
            <div className='landing-2'>
              <div className='light-overlay'>
                <Suspense fallback={<Fallback />}>
                  <AthSwiper />
                </Suspense>
              </div>
            </div>
            <div className='landing-3-header'>
              <p>
                ONS<span className='id'>ID</span>E for Coaches
              </p>
            </div>
            <div className='landing-3'>
              <div className='light-overlay'>
                <Suspense fallback={<Fallback />}>
                  <CoachSwiper />
                </Suspense>
              </div>
            </div>
            <div className='landing-4-header'>
              <p>
                ONS<span className='id'>ID</span>E for Organisations
              </p>
            </div>
            <div className='landing-4'>
              <div className='light-overlay'>
                <Suspense fallback={<Fallback />}>
                  <OrgSwiper />
                </Suspense>
              </div>
            </div>
            <div className='landing-contact'>
              <div className='contact-info'>
                <div className='email'>
                  <i className='fas fa-envelope'></i>
                  <p>support@onsideapp.com</p>
                </div>
                <div className='instagram'>
                  <i className='fa-brands fa-instagram'></i>
                  <p>@onsideapp</p>
                </div>
              </div>
              <div className='legal'>
                <Link to='/terms'>
                  <p className="terms">Terms</p>
                </Link>
                <p className='divider'>|</p>
                <Link to='/privacy'>
                  <p className="privacy">Privacy</p>
                </Link>
                <p className='divider'>|</p>
                <div className='reserved'>
                  <i className='fa-regular fa-copyright'></i>
                  <p className='year'>2023</p>
                  <p>All Rights Reserved</p>
                </div>
              </div>
            </div>
          </section>
        </Fragment>
      )}
    </Fragment>
  );
};

Landing.propTypes = {
  isAuthenticated: PropTypes.bool,
  loading: PropTypes.bool,
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  loading: state.auth.loading,
  user: state.auth.user,
});

export default connect(mapStateToProps)(Landing);
