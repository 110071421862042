import { FETCH_TIMELINE, TIMELINE_ERROR, CLEAR_TIMELINE } from '../actions/types';

const initialState = {
  data: null,
  loadingTimeline: true,
  error: {},
};

export default function timelineReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case FETCH_TIMELINE:
      return {
        data: payload,
        loadingTimeline: false,
        error: {},
      };
    case TIMELINE_ERROR:
      return {
        ...state,
        loadingTimeline: false,
        error: payload,
      };
    case CLEAR_TIMELINE:
        return {
          data: null,
          loadingTimeline: true,
          error: {},
        };
    default:
      return state;
  }
}
