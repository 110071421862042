import {
  GET_COACH_PROFILE,
  COACH_PROFILE_ERROR,
  UPDATE_PROFILE_COACH,
  GET_ATH_PROFILES,
  GET_ATHLETE_PROFILE,
  CLEAR_ATHLETE_PROFILE,
  CLEAR_COACH_PROFILE,
  GET_OTHER_COACH_PROFILE,
  COACH_GET_ORG_PROFILE,
  WATCHLIST_ADD,
  WATCHLIST_REMOVE,
  COACH_ADD_FOLLOW,
  COACH_REMOVE_FOLLOW,
  REQUEST_CONTACT_DETAILS,
  COACH_NOTIFICATIONS,
  COACH_NOTIFICATION_READ,
  LOADING_COACH,
} from '../actions/types';

const initialState = {
  profile: null,
  profiles: [],
  selectedProfile: null,
  query: [],
  loading: true,
  error: {},
};

export default function coachReducer(state = initialState, action) {
  const { type, payload, query, coachProf } = action;
  switch (type) {
    case GET_COACH_PROFILE:
    case UPDATE_PROFILE_COACH:
      return {
        ...state,
        profile: payload,
        profiles: [],
        selectedProfile: null,
        query: [],
        loading: false,
      };
    case GET_ATHLETE_PROFILE:
      return {
        ...state,
        profile: coachProf,
        selectedProfile: payload,
        loading: false,
      };
    case CLEAR_ATHLETE_PROFILE:
      return {
        ...state,
        selectedProfile: null,
        loading: true,
      };
    case GET_ATH_PROFILES:
      return {
        ...state,
        profile: coachProf,
        profiles: payload,
        selectedProfile: null,
        query: query,
        loading: false,
      };
    case GET_OTHER_COACH_PROFILE:
    case COACH_GET_ORG_PROFILE:
      return {
        ...state,
        profile: coachProf,
        selectedProfile: payload,
        loading: false,
      };
    case WATCHLIST_ADD:
    case WATCHLIST_REMOVE:
    case COACH_ADD_FOLLOW:
    case COACH_REMOVE_FOLLOW:
    case REQUEST_CONTACT_DETAILS:
    case COACH_NOTIFICATIONS:
    case COACH_NOTIFICATION_READ:
      return {
        ...state,
        profile: payload,
        loading: false,
      };
    case COACH_PROFILE_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case LOADING_COACH:
      return {
        ...state,
        loading: true,
      };
    case CLEAR_COACH_PROFILE:
      return {
        ...state,
        profile: null,
        profiles: [],
        selectedProfile: null,
        query: [],
        loading: true,
      };
    default:
      return state;
  }
}
